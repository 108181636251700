@use 'sass:color';

@use 'bootstrap';
@use 'app';

body * {
  //border: 1px solid red;
}
.search-result-card {
  min-height: 100px;

  @media (min-width: 768px) {
    min-height: 175px;
  }
}

@media (min-width: 768px) {
  #filters-section.d-md-block {
    display: block !important;
  }
}

a.clickable-search-term {
  color: unset;
  text-decoration-line: underline;
  text-decoration-color: bootstrap.$gray-500;
  -webkit-text-decoration-line: underline;
  -webkit-text-decoration-color: bootstrap.$gray-500;
  cursor: pointer;

  &:hover {
    color: color.adjust(bootstrap.$link-color, $lightness: -50%, $space: hsl);
  }
}

mark {
  color: unset;
  background-color: rgba(bootstrap.$gray-200, 0.7);
  padding: 0 0.08em;
}

.ais-SearchBox-loadingIndicator {
  position: absolute;
  top: 21px;
  right: 60px;
}

@media (max-width: 1200px) {
  h1,
  .h1 {
    font-size: calc(1.6rem + 1.2vw);
  }
}

#sort-by {
  max-width: 185px;
}

.ais-RefinementList-searchBox {
  max-width: 250px;
}
